<template>
    <div class="main">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1>Template Variables</h1>
                    <a href="" class="btn" @click.prevent="openSetVars">Set Variables</a>
                    <router-link class="btn" to="/dispute/configurator" active-class="active" style="float: right;">Back</router-link>
                </div>
            </div>
            <form class="row" @submit.prevent="setVars" v-if="showSetVars">
                <div class="col-4">
                    <div class="form-group">
                        <label for="">Company Name:</label>
                        <input type="text" v-model="form.companyName" placeholder="Enter Company Name" class="form-control" />
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="">Email:</label>
                        <input type="text" v-model="form.email" placeholder="Enter Email" class="form-control" />
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="">City:</label>
                        <input type="text" v-model="form.city" placeholder="Enter City" class="form-control" />
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="">Post Code:</label>
                        <input type="text" v-model="form.postCode" placeholder="Enter Post Code" class="form-control" />
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="">Street:</label>
                        <input type="text" v-model="form.street" placeholder="Enter Street" class="form-control" />
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="">House Number:</label>
                        <input type="text" v-model="form.houseNumber" placeholder="Enter House Number" class="form-control" />
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="">Phone Number:</label>
                        <input type="text" v-model="form.phoneNumber" placeholder="Enter Phone Number" class="form-control" />
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="">KVK:</label>
                        <input type="text" v-model="form.kvk" placeholder="Enter KVK" class="form-control" />
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="">BTW:</label>
                        <input type="text" v-model="form.btw" placeholder="Enter vat" class="form-control" />
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="">Bank Account:</label>
                        <input type="text" v-model="form.bankAccount" placeholder="Enter Bank Account" class="form-control" />
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="">BIC:</label>
                        <input type="text" v-model="form.bic" placeholder="Enter BIC" class="form-control" />
                    </div>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="" style="pointer-events: none; color: transparent;">AA</label>
                                <button type="button" style="width: 100%;" @click.prevent="showSetVars = false" class="btn">Cancel</button>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="" style="pointer-events: none; color: transparent;">AA</label>
                                <button type="submit" style="width: 100%;" class="btn">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="row" style="margin-top: 40px;">
               <div class="col-8">
                   <ul class="list-group">
                       <li class="list-group-item">Company Name: {{ vars.companyName }}</li>
                       <li class="list-group-item">Email: {{ vars.email }}</li>
                       <li class="list-group-item">City: {{ vars.city }}</li>
                       <li class="list-group-item">Post Code: {{ vars.postCode }}</li>
                       <li class="list-group-item">Street: {{ vars.street }}</li>
                       <li class="list-group-item">House Number: {{ vars.houseNumber }}</li>
                       <li class="list-group-item">Phone Number: {{ vars.phoneNumber }}</li>
                       <li class="list-group-item">KVK: {{ vars.kvk }}</li>
                       <li class="list-group-item">BTW: {{ vars.btw }}</li>
                       <li class="list-group-item">Bank Account: {{ vars.bankAccount}}</li>
                       <li class="list-group-item">BIC: {{ vars.bic }}</li>
                       <li class="list-group-item">Current Year: {{ todaysYear }}</li>
                   </ul>
               </div>
                <div class="col-4">
                    <div class="variable-logo">
                        <img :src="logoUrl" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		name: "TemplateVariables",
        data() {
			return {
				showSetVars: false,
				todaysYear: new Date().getFullYear(),
                logoUrl: '',
                form: {
	                bankAccount: '',
	                bic: '',
	                btw: '',
	                city: '',
	                companyName: '',
	                email: '',
	                houseNumber: '',
	                kvk: '',
	                phoneNumber: '',
	                postCode: '',
	                street: '',
                },
                vars: {
	                bankAccount: '',
	                bic: '',
	                btw: '',
	                city: '',
	                companyName: '',
	                email: '',
	                houseNumber: '',
	                kvk: '',
	                phoneNumber: '',
	                postCode: '',
	                street: '',
                    logo: ''
                }
            }
        },
        mounted() {
            this.getVars()
        },
        methods: {
			openSetVars() {
				this.showSetVars = !this.showSetVars

				this.form = JSON.parse(JSON.stringify(this.vars));
            },
			getVars() {
				disputeAxios
					.get('/api/template-variables/get')
                    .then(({data}) => {
                    	if (data.code === 2007) {
		                    EventBus.$emit('showAlertModal', data.message)
                    	} else {
                    		this.vars = data
                            this.logoUrl = data.logo
                    	}
                    })
                    .catch(err => console.error(err))
            },
	        setVars() {
				let data = {
					bankAccount: this.form.bankAccount,
					bic: this.form.bic,
					btw: this.form.btw,
					city: this.form.city,
					companyName: this.form.companyName,
					email: this.form.email,
					houseNumber: this.form.houseNumber,
					kvk: this.form.kvk,
					phoneNumber: this.form.phoneNumber,
					postCode: this.form.postCode,
					street: this.form.street
                }

                for (let i in data) {
					if (data[i].length < 1) {
						EventBus.$emit('showAlertModal', 'Please fill all inputs')
						return false
					}
				}

		        if (!Helper.validateMail(data.email)) {
			        EventBus.$emit('showAlertModal', 'Please enter valid email address')
			        return false
				}

		        disputeAxios
			        .post('/api/template-variables/set', data)
			        .then((res) => EventBus.$emit('showAlertModal', res.data.message))
			        .then(() => this.showSetVars = false)
			        .then(() => this.vars = data)
			        .catch(err => console.error(err))
	        }
        }
	}
</script>

<style scoped lang="scss">
    .form-group {
        margin-top: 20px;
    }
    .variable-logo {
        max-width: 100%;
        width: 260px;
        height: 90px;
        float: right;
        img {
            width: 100%;
            height: 100%;
        }
    }
</style>